/* eslint-disable @next/next/no-img-element */
'use client';
import { FC, useState } from 'react';

import { useRouter } from 'next/navigation';

import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { FlexBox, FlexRowCenter } from 'libs/components/flex-box';
import {
  SearchBarHome,
  SearchIcon,
} from 'libs/components/header-with-nav/style';
import { typography } from 'theme/typography';
import StyledImageBox from '../StyledImageBox';
import { StyledBanner, StyledInput, StyledVideo } from './style';

interface Banner1Props {
  title?: string;
}

const images = [
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1886.webp`,
    title: 'Background',
    alt: 'BackgroundImage',
    link: '/videos/background',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1889.webp`,
    title: 'Logo',
    alt: 'LogoImage',
    link: '/premiere-pro-templates/logo',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1890.webp`,
    title: 'Wedding',
    alt: 'Wedding',
    link: '/premiere-pro-templates/wedding',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/home/Slideshow_002.png`,
    title: 'Slideshow',
    alt: 'Slideshow',
    link: '/after-effects-templates/slideshows',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1883.webp`,
    title: '3D',
    alt: '3D',
    link: '/images/3d',
  },

  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1884.webp`,
    title: '2D',
    alt: '2D',
    link: '/images/2d',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1887.webp`,
    title: 'Birthday',
    alt: 'Birthday',
    link: '/premiere-pro-templates/birthday',
  },
  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/AwardShow1.webp`,
    title: 'Awards Show',
    alt: 'Awards Show',
    link: '/after-effects-templates/awards-show',
  },

  {
    src: `${process.env.CONTENT_BASE_URL}/tags-images/Rectangle1891.webp`,
    title: 'Trailer',
    alt: 'Trailer',
    link: '/after-effects-templates/trailer',
  },
];

const Banner1: FC<Banner1Props> = () => {
  const router = useRouter();

  const [searchInput, setSearchInput] = useState('');

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '1rem',
        gap: { xs: '1rem', md: '2rem' },
        backgroundColor: 'white',
      }}
    >
      <StyledBanner
        flexDirection={'column'}
        gap={{ md: '1rem', xs: '0.7rem' }}
        width={'100%'}
        position={'relative'}
        overflow={'hidden'}
      >
        <StyledVideo
          loop
          autoPlay
          muted
          playsInline
          width={300}
          height={200}
          poster={`${process.env.CONTENT_BASE_URL}/HomePageBackground.webp`}
          style={{ width: '100%', height: 'auto' }}
          preload="metadata"
        >
          <source
            src={`${process.env.CONTENT_BASE_URL}/static/landingVideo.webm`}
            type="video/webm"
          />
        </StyledVideo>
        <Stack
          sx={{
            position: 'absolute',
            top: '10',
            gap: { md: '1rem', xs: '0.7rem' },
            zIndex: 9,
          }}
        >
          <Box>
            <Box
              sx={{
                width: {
                  md: '47.5rem',
                  sm: '100%',
                },
              }}
            >
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Typography
                  fontWeight={600}
                  variant="h1"
                  fontFamily={typography.fontFamily}
                  fontSize={{ md: '3rem', xs: '1.4rem' }}
                  width={{ md: '100%', xs: '80%' }}
                  lineHeight={{ md: '55px', xs: '1.5rem' }}
                  color={'#FFFFFF'}
                >
                  Spark Endless Creation with Premium Design Assets
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'center'}>
            <Typography
              width={{ md: '100%', xs: '80%' }}
              color={'#F5F4F4'}
              fontSize={{ xs: '0.8rem', md: '0.875rem' }}
            >
              Unlock the World&apos;s Leading Royalty-Free Design Collection
            </Typography>
          </Box>
          <Stack spacing={3} width={'100%'}>
            <FlexRowCenter width={'100%'} padding={'0 0.3rem'}>
              <Box
                width={{
                  sm: '90%',
                }}
                height={'2.83rem'}
              >
                <SearchBarHome
                  name="homesearchbar"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (searchInput.trim().length === 0) {
                      setSearchInput('');

                      return;
                    }

                    router.push(`/search?searchterm=${searchInput}&page=1`);
                  }}
                  sx={{
                    maxWidth: '100%',
                    marginTop: '0.5rem',
                    padding: '0.5rem',
                    backgroundColor: '#FFFDFC',
                  }}
                >
                  <StyledInput
                    type="text"
                    placeholder="Search"
                    sx={{ fontSize: { xs: '0.6rem', md: '0.75rem' } }}
                    value={searchInput}
                    onChange={(e: any) => setSearchInput(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    type="submit"
                    sx={{
                      width: { xs: '6.5rem', md: '8rem' },
                      height: { xs: '1.7rem', md: '2rem' },
                      gap: '0.25rem',
                      fontWeight: 400,
                      fontSize: { xs: '0.6rem', md: '0.75rem' },
                      backgroundColor: '#6E5BE4',
                      color: 'white',
                      ':hover': {
                        backgroundColor: '#5D4CC6',
                      },
                    }}
                  >
                    Search <SearchIcon />
                  </Button>
                </SearchBarHome>
              </Box>
            </FlexRowCenter>
          </Stack>
        </Stack>
      </StyledBanner>
      <FlexRowCenter>
        <FlexBox
          gap={{ xs: '1rem', md: '1.7rem' }}
          sx={{
            overflowX: { xs: 'scroll', md: 'none' },
            paddingLeft: { xs: '0.5rem', md: '0rem' },
          }}
          justifyContent={{ md: 'center', xs: 'start' }}
          width={'100%'}
        >
          {images.map((item) => (
            <Stack spacing={1} key={item.alt}>
              <StyledImageBox
                image={item.src}
                tagName={item.title}
                source={item.link}
              />
            </Stack>
          ))}
        </FlexBox>
      </FlexRowCenter>
    </Box>
  );
};

export default Banner1;
